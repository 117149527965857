import { customerPassRoutePush, CustomerPassTypedRoute } from "@customer-pass/definitions/routeDefinitions";
import { requestLoginTokenCodeEmail, requestLoginTokenEmail } from "@customer-pass/redux/actionCreators/auth";
import { useNovelCustomerPassDispatch, useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";
import { Box } from "@novel/shared/components/Box";
import cx from "classnames";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { typedFrontendPassApi } from "@customer-pass/utils/typedFrontendPassApi";
import { SixDigitCodeComponent } from "@novel/shared/components/SixDigitCodeComponent";
import { useAsyncCallback } from "@novel/shared/hooks/useAsyncCallback";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { redirectOnFrontend } from "@novel/shared/utils/locationUtils";
import { createGlobalStyle } from "styled-components";
import { loadSession } from "@customer-pass/redux/actionCreators/session";
import { LayoutDesktopCmp } from "@novel/shared/components/PassPreview/LayoutDesktop";
import { useGuessedPhoneOperatingSystem, useWalletPassInstallFlow } from "@customer-pass/redux/reducers/walletPassInstallFlow";
import { DEFAULT_WELCOME_PASS_TEXT, DEFAULT_WELCOME_REFFERALS_TEXT, NOVEL_PASS_USER_EMAIL_PARAM, REFEREE_BALANCE_TEXT_EDITOR_MASK, REFERRER_TEXT_EDITOR_MASK, SHOP_NAME_EDITOR_MASK } from "@novel/shared/utils/appConstants";
import { validateEmail } from "@novel/shared/utils/validateEmail";
import { useCurrencyCode, useResolvedHost, useRewardsTier, useUserDisplayName } from "@customer-pass/redux/reducers/auth";
import { PassAnimation } from "@novel/shared/components/PassPreview/shared/PassAnimation";
import { dismissToast, loadingToast } from "@novel/shared/utils/toastUtils";
import gmailAuthIcon from "@novel/shared/icons/GmailAuthIcon.png";
import Image from "next/legacy/image";
import checkIsWebView from "is-ua-webview";
import noop from "lodash/noop";
import pickBy from "lodash/pickBy";
import { formatMoney } from "@novel/shared/utils/formatMoney";
import { removeTextAlignmentFromDraftJs } from "@novel/shared/components/DraftEditor/utils";
import { BigIntStr } from "@novel/shared/interfaces/shared/BigIntStr";
import { useAsyncEffect } from "@novel/shared/hooks/useAsyncEffect";
import { getPassLinkForHandle } from "@novel/shared/utils/getPassLinkForHandle";
import { setCookie } from "@novel/shared/utils/cookieUtils";
import { HOST_WITH_CORRECTED_SUBDOMAINS, oauthPrefixedHostWithProtocol } from "@novel/shared/utils/appDomainConstants";
import { NOVEL_PASS_CAMPAIGN_ID_PARAM, NOVEL_PASS_LINK_TYPE_PARAM, NOVEL_PASS_CODE_PARAM } from "@novel/shared/utils/appStorefrontConstants";
import { IFingerprint } from "@novel/shared/interfaces/Fingerprint";
import { claimBalance } from "@novel/shared/utils/claimBalance";

// styling with this is necessary for SSR to work
const LoginPageCmpStyle = createGlobalStyle`
    .rs-modal-body {
        overflow: hidden !important;
    }
    .form-submit-area {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .already-account {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: inline-block;
    }

    .edit-text-light {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-decoration-line: underline;
        cursor: pointer;
        margin-left: 5px;
    }
    .account-extra {
        margin-top: 10px;
        text-align: center;
    }
    .small-logo-container {
        margin-left: auto;
        margin-right: auto;
    }
    .box-content-login-prompt{
        line-height: 1.2 !important;
        text-align: center;
    }
    .box-content-login-prompt-editor {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .box-content-login-prompt-editor * {
        line-height: 1.2 !important;
    }
    .box-content-login-prompt-editor h1 {
        font-size: 40px;
        color: black;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        margin-bottom: 1rem;
    }
    .box-content-login-prompt-editor h2 {
        font-size: 30px;
        color: #000000;
        font-weight: 600 !important;
        font-family: "Poppins", sans-serif;
        margin-bottom: 1rem;
    }
    .box-content-login-prompt-editor h3 {
        font-size: 25px;
        color: #000000;
        font-weight: 600 !important;
        font-family: "Poppins", sans-serif;
        margin-bottom: 1rem;
    }
    .box-content-login-prompt-editor p strong{
        // color: #3371CE;
    }
    .box-content-login-prompt-editor h4 {
        font-size: 22px;
        font-weight: 600 !important;
        font-family: "Poppins", sans-serif;
        margin-bottom: 1rem;
    }
    .box-content-login-prompt-editor p {
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px !important;
        align-items: center;
        color: #000000;
        // margin-top: 0;
    }

    .svgImageContainer {
        width: 180px;
        height: 206px;
    }

    .continue-button {
        background: black;
        color: white;
        padding: 15px 25px;
        min-width: 230px;
        max-width: 230px;
        height: 45px;
        border-radius: 100px;
        cursor: pointer;
        font-size: 14px;
        white-space: nowrap;
        line-height: 1;
    }
    .inverse-continue-button {
        color: black;
        background: white;
        border: 1px solid #CCCCCC;
        transition: border-color ease-in-out .15s, color ease-in-out .15s;
    }
    .inverse-continue-button:hover, .inverse-continue-button:disabled {
        border-color rgba(204, 204, 204, 0.5);
        color: rgba(0,0,0,0.5);
    }

    .text-button {
        font-size: 12px;
        line-height: 1;
        cursor: pointer;
        padding: 0;
        margin: 0;
        height: 45px;
        min-width: 230px;
        max-width: 230px;
        text-decoration: underline;
        transition: opacity ease-in-out .15s;
    }
    .text-button:hover {
        opacity: 0.7;
    }

    .auth-email-input {
        padding-bottom: 0;
        margin-left: 4px;
        font-size: 14px;
    }
    .send-link-button {
        min-width: initial;
        max-width: initial;
    }
    .send-link-button:disabled {
        user-select: none;
    }

    .auth-text {
        font-size: 28px;
        color: black;
        font-weight: 600;
        text-align: center;
        font-family: "Poppins", sans-serif;
    }
    .auth-subtext {
        font-size: 13px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        line-height: 1.5;
    }
    .login-auth-auth-state {
        margin: 4rem 0;
        text-align: center;
    }
    .login-auth-auth-state > button {
        width: 50%;
    }

    .code-message {
        margin: 1rem 0;
    }
    .resend-code {
        font-weight: bold;
        display: contents;
        color: black;
        cursor: pointer;
    }
    .modalWiggle {
        -webkit-animation: kf_shake 0.4s 1 linear;
        -moz-animation: kf_shake 0.4s 1 linear;
        -o-animation: kf_shake 0.4s 1 linear;
    }
    .edit-text-light {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-decoration-line: underline;
        cursor: pointer;
        margin-left: 5px;
        color: #000000;
        background: transparent;
    }
    .back-to-login {
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 1px;
        color: rgb(0, 0, 0);
    }
    .hello-subtext-mini {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        text-align: center;
    }
    .text-for-small-lookups, .login-auth {
        justify-content: center;
        align-items: center;
    }
    @-webkit-keyframes kf_shake {
        0% {
            -webkit-transform: translate(30px);
        }
        20% {
            -webkit-transform: translate(-30px);
        }
        40% {
            -webkit-transform: translate(15px);
        }
        60% {
            -webkit-transform: translate(-15px);
        }
        80% {
            -webkit-transform: translate(8px);
        }
        100% {
            -webkit-transform: translate(0px);
        }
    }
    @-moz-keyframes kf_shake {
        0% {
            -moz-transform: translate(30px);
        }
        20% {
            -moz-transform: translate(-30px);
        }
        40% {
            -moz-transform: translate(15px);
        }
        60% {
            -moz-transform: translate(-15px);
        }
        80% {
            -moz-transform: translate(8px);
        }
        100% {
            -moz-transform: translate(0px);
        }
    }
    @-o-keyframes kf_shake {
        0% {
            -o-transform: translate(30px);
        }
        20% {
            -o-transform: translate(-30px);
        }
        40% {
            -o-transform: translate(15px);
        }
        60% {
            -o-transform: translate(-15px);
        }
        80% {
            -o-transform: translate(8px);
        }
        100% {
            -o-origin-transform: translate(0px);
        }
    }

    .button-big {
        width: 125px;
    }
    .form-box {
        width: 100%;
        row-gap: 1rem !important;
    }

    @media (max-height: 570px){
        .form-box {
            width: 100%;
            row-gap: 1.5rem !important;
        }
    }

    .continue-button-container {
        align-items: center;
        margin-bottom: 1rem;
    }

    @media (min-width: 970px) {
        .text-button {
            transform: translateY(-2px);
            text-decoration: none;
            color: #777;
            font-weight: normal;
        }
        .continue-button-container {
            align-items: flex-start;
            margin-bottom: 0;
        }
        .box-content-login-prompt-editor {
            text-align: left;
        }
        .auth-code-container {
            width: 80%;
        }
        .form-submit-area {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        .account-extra {
            display: inline-block;
            margin-left: 20px;
            margin-top: 0;
        }
        .hello-subtext-mini {
            text-align: inherit;
        }
        .small-logo-container {
            display: none !important;
        }
        .auth-text {
            text-align: left;
        }
        .auth-subtext {
            text-align: left;
        }
        .text-for-small-lookups, .login-auth {
            justify-content: flex-start;
            align-items: flex-start;
        }

        .box-login {
            padding-left: 10px;
            padding-top: 73px;
            padding-right: 10px;
        }
        .form-box {
            width: 400px;
            row-gap: 5.5rem;
        }
        .back-to-login {
            font-size: 12px;
            text-decoration: underline;
            text-underline-offset: 3px;
            text-decoration-thickness: 1px;
            color: rgb(0, 0, 0);
            margin-bottom: 1px;
        }
        .box-content-login-prompt {
            align-items: flex-start;
            text-align: left;
        }
    }
`;
type LoginPageStates = "login-request" | "login-auth" | "login-code-provided-via-url";
const CODE_ERROR = "You have input the wrong code, please try again.";
const CODE_RESEND = "A new code has been sent to your email.";
export const LoginPageCmp: CustomerPassTypedRoute<"/login"> = ({
  props
}) => {
  let isWebView = false;
  if (typeof window !== "undefined") {
    isWebView = checkIsWebView(window.navigator.userAgent);
  }
  let providedEmail = props[NOVEL_PASS_USER_EMAIL_PARAM];
  if (providedEmail && !validateEmail(providedEmail)) {
    providedEmail = decodeURIComponent(providedEmail);
  }
  const providedReferralCode = props.referralCode;
  const providedSourceTag = props.sourceTag;
  const noCodeFlag = !!props.nocode;
  const providedOfferLink = props.redirectUrl;
  const providedCampaignId = props[NOVEL_PASS_CAMPAIGN_ID_PARAM];
  const providedPassLinkType = props[NOVEL_PASS_LINK_TYPE_PARAM] || typeof window !== "undefined" ? window.location.hash.split("passLinkType=")[1] : "";
  const providedCode = props[NOVEL_PASS_CODE_PARAM];
  const userIsLoading = useNovelCustomerPassSelector(state => state.auth.isLoadingAuth);
  const resolvedCustomer = useNovelCustomerPassSelector(state => state.auth.resolvedCustomer);
  const resolvedOrg = useNovelCustomerPassSelector(state => state.orgData.resolvedOrg);
  const currencyCode = useCurrencyCode();
  const resolvedHost = useResolvedHost();
  const rewardsTier = useRewardsTier();
  const walletPassInstallFlow = useWalletPassInstallFlow();
  const guessedPhoneOperatingSystem = useGuessedPhoneOperatingSystem();
  const resolvedPhoneOperatingSystem = walletPassInstallFlow === "ios-phone" ? "ios" : walletPassInstallFlow === "android-phone" ? "android" : guessedPhoneOperatingSystem;
  const isMobile = walletPassInstallFlow !== "large-device";
  const loginEmailStatus = useNovelCustomerPassSelector(state => state.auth.loginEmailStatus);
  const dispatch = useNovelCustomerPassDispatch();
  const passPageLink = resolvedOrg && resolvedOrg.currentOrgHandle && getPassLinkForHandle(resolvedOrg.currentOrgHandle);
  const [email, setEmail] = useState<string>(providedEmail || "");
  const userDisplayName = useUserDisplayName();
  const resolvedUserDisplayName = useMemo(() => email || userDisplayName, [email, userDisplayName]);
  const emailIsValid = useMemo(() => validateEmail(email), [email]);
  const onEmailChange = useCallback((e: any) => {
    setEmail(e.target.value && e.target.value.trim());
  }, []);
  const referredBy = useNovelCustomerPassSelector(state => state.passUi.resolvedReferrer);
  const {
    isLoading,
    error,
    data
  } = useVisitorData({
    extendedResult: true
  }, {
    immediate: true
  });
  useEffect(() => {
    if (typeof document !== "undefined" && data) {
      setCookie({
        name: "fingerprint",
        value: JSON.stringify(data)
      });
    }
  }, [data]);
  const loginWithFingerprint = useAsyncCallback(async () => {
    let redirect = "";
    if (providedOfferLink) {
      redirect = `${providedOfferLink}#${qs.stringify({
        [NOVEL_PASS_CODE_PARAM]: providedCode
      })}`;
    }
    if (providedEmail && isMobile && !isLoading && !!redirect.length && data) {
      await typedFrontendPassApi.postReq("/api/customer/fingerprint/login", {
        reqBody: {
          email: providedEmail,
          fingerprintId: data.visitorId,
          redirectUrl: redirect,
          browser: data.browserName,
          ipAddress: data.ip
        }
      }).then(item => {
        if (item.type === "success") {
          if (item.body.shouldLogIn) {
            redirectOnFrontend(redirect);
          }
        }
      }).catch(err => {
        console.log("err", err);
      });
    }
  }, [providedOfferLink, providedEmail, isMobile, isLoading, data, providedCampaignId, providedCode, providedPassLinkType]);
  useAsyncEffect(loginWithFingerprint, noop, [loginWithFingerprint]);
  const loginForSelfReferral = useAsyncCallback(async () => {
    if (data?.visitorId) {
      const referrerFingerprints: IFingerprint[] = (referredBy?.customerUser as any)?.person?.fingerprints || [];
      const referrerContainsCurrentFingerprint = referrerFingerprints.some(fingerPrint => fingerPrint.fingerprint === data.visitorId);
      if (referredBy?.email && isMobile && !isLoading && referrerContainsCurrentFingerprint) {
        await typedFrontendPassApi.postReq("/api/customer/fingerprint/login", {
          reqBody: {
            email: referredBy.email,
            fingerprintId: data.visitorId,
            browser: data.browserName,
            ipAddress: data.ip,
            redirectUrl: passPageLink || ""
          }
        }).then(item => {
          if (item.type === "success") {
            if (item.body.shouldLogIn) {
              redirectOnFrontend(passPageLink || "");
            }
          }
        }).catch(err => {
          console.log("err", err);
        });
      }
    }
  }, [referredBy, isMobile, isLoading, providedOfferLink, data]);
  useAsyncEffect(loginForSelfReferral, noop, [loginForSelfReferral]);
  const onSubmit = useAsyncCallback(async (e?: React.SyntheticEvent<any>) => {
    if (e) {
      e.nativeEvent.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
    }
    const cleanedEmail = typeof email === "undefined" ? providedEmail || "" : email;
    if (cleanedEmail) {
      if (isMobile) {
        await dispatch(requestLoginTokenEmail({
          email: cleanedEmail,
          referralCode: providedReferralCode,
          redirectUrl: providedOfferLink,
          sourceTag: providedSourceTag,
          [NOVEL_PASS_CAMPAIGN_ID_PARAM]: providedCampaignId,
          [NOVEL_PASS_LINK_TYPE_PARAM]: providedPassLinkType
        }));
      } else {
        await dispatch(requestLoginTokenCodeEmail({
          email: cleanedEmail
        }));
      }
      customerPassRoutePush("/login", {}, {
        [NOVEL_PASS_USER_EMAIL_PARAM]: cleanedEmail,
        referralCode: providedReferralCode,
        sourceTag: providedSourceTag,
        [NOVEL_PASS_LINK_TYPE_PARAM]: providedPassLinkType,
        redirectAppUrl: providedOfferLink,
        [NOVEL_PASS_CAMPAIGN_ID_PARAM]: providedCampaignId,
        [NOVEL_PASS_CODE_PARAM]: providedCode
      });
    }
  }, [dispatch, email, providedEmail, isMobile, providedReferralCode, providedSourceTag]);
  const [loginModalState, setLoginModalState] = useState("login-code-provided-via-url" as LoginPageStates);
  useEffect(() => {
    if (!userIsLoading && !resolvedCustomer) {
      if (providedCode && providedCode.length === 6) {
        setLoginModalState("login-code-provided-via-url");
      } else {
        setLoginModalState("login-request");
      }
    }
  }, [providedCode, userIsLoading, resolvedCustomer]);
  const redirectToLoginSuccess = useCallback(() => {
    setLoginModalState("login-auth");
  }, []);
  const [codeMessage, setCodeMessage] = useState("");
  const [modalWiggle, setModalWiggle] = useState(false);
  const [sixDigitCode, sixDigitCodeOnChange] = useState("");
  const passLinkLoginText = `
    <h1 id="login-to-continue-">Login to Continue!</h1><p>Continue with Gmail or email login link to get your pass.</p>
    `.trim();
  const passLoginWelcomeText = resolvedOrg?.rewardsConfig.passLoginWelcomeText || DEFAULT_WELCOME_PASS_TEXT.replaceAll(SHOP_NAME_EDITOR_MASK, `${resolvedOrg?.rewardsConfig?.passOrgNameOverride || resolvedOrg?.org.orgName}`.trim());
  const emailOrName = referredBy && referredBy.customerUser && (referredBy.customerUser.firstName || referredBy.customerUser.lastName) ? `${referredBy.customerUser.firstName} ${referredBy.customerUser.lastName}` : referredBy?.email || "Unknown";
  let balance = BigIntStr.toBigIntStr(0);
  if (rewardsTier) {
    balance = claimBalance(rewardsTier!, currencyCode, referredBy?.rewardsStatus?.currentRewardsTier);
  }
  const referrerLoginWelcomeText = (resolvedOrg?.rewardsConfig.referralLoginWelcomeText || DEFAULT_WELCOME_REFFERALS_TEXT).replaceAll(REFERRER_TEXT_EDITOR_MASK, emailOrName).replaceAll(REFEREE_BALANCE_TEXT_EDITOR_MASK, `${formatMoney(balance || BigIntStr.toBigIntStr(0), currencyCode)}${currencyCode === "PTS" ? " POINTS" : ""}`);
  const logoImageUrl = rewardsTier?.iconImageUrl || resolvedOrg?.brand?.logoUrl || rewardsTier?.googleLogoImageUrl || resolvedOrg?.brand?.favicon || "";
  const stripImageUrl = rewardsTier?.stripImageUrlx3 || rewardsTier?.stripImageUrlx2 || rewardsTier?.stripImageUrl || resolvedOrg?.brand?.ogImageUrl || resolvedOrg?.brand?.twitterImageUrl || "";

  // QR links to the org's mobile pass page
  const resolvedQrLink = resolvedOrg?.currentOrgHandle ? getPassLinkForHandle(resolvedOrg.currentOrgHandle) : "";
  const checkCodeAndLogin = useAsyncCallback(async (code: string, errorHandle: () => void) => {
    const {
      onSuccessToast,
      onErrorToast
    } = loadingToast("Verifying auth code...");
    await typedFrontendPassApi.postReq("/api/customer/auth/passwordless/login/code", {
      reqBody: {
        code,
        email: providedEmail || email
      }
    }).then(async res => {
      if (res.type === "error") {
        throw res.error;
      }

      /// if they're coming from an auth URL then showing the toast looks odd
      if (providedCode) {
        onSuccessToast("Authentication Verified!");
      }
      return await dispatch(loadSession(typedFrontendPassApi, {
        referralCode: providedReferralCode,
        sourceTag: providedSourceTag
      }));
    }).then(async loadedSession => {
      if (loadedSession.type === "LOADED_RESOLVED_CUSTOMER") {
        const currentHandle = window.location.host.replace(`.${HOST_WITH_CORRECTED_SUBDOMAINS}`, "");
        const customerHandle = loadedSession.payload.resolvedCustomer?.customerOrgHandle.handle;
        if (providedPassLinkType) {
          customerPassRoutePush("/auth", {}, {
            redirectUrl: providedOfferLink,
            [NOVEL_PASS_CAMPAIGN_ID_PARAM]: providedCampaignId,
            [NOVEL_PASS_USER_EMAIL_PARAM]: providedEmail,
            [NOVEL_PASS_LINK_TYPE_PARAM]: providedPassLinkType,
            [NOVEL_PASS_CODE_PARAM]: providedCode
          });
        } else {
          if (currentHandle !== customerHandle) {
            // will cause redirect to customer's correct handle
            return window.location.reload();
          } else {
            setTimeout(() => {
              customerPassRoutePush("/");
            }, 0);
            setTimeout(() => {
              dismissToast();
            }, 1000);
          }
        }
      }
    }).catch(error => {
      onErrorToast(error.message);
      errorHandle();
    });
  }, [dispatch, sixDigitCode, email, providedEmail, providedReferralCode, providedSourceTag]);
  useEffect(() => {
    if (!userIsLoading && !resolvedCustomer && providedEmail && providedCode && providedCode.length === 6) {
      checkCodeAndLogin(providedCode, () => {
        customerPassRoutePush("/login", {}, {
          [NOVEL_PASS_USER_EMAIL_PARAM]: providedEmail,
          referralCode: providedReferralCode,
          sourceTag: providedSourceTag
        });
        setLoginModalState("login-auth");
      });
    }
  }, [providedCode, providedEmail, checkCodeAndLogin, resolvedCustomer, userIsLoading, providedReferralCode, providedSourceTag]);
  useEffect(() => {
    if ((sixDigitCode || "").trim().length === 6) {
      checkCodeAndLogin(sixDigitCode, () => {
        setCodeMessage(CODE_ERROR);
        setModalWiggle(true);
        sixDigitCodeOnChange("");
        document?.getElementById("lastDigit")?.blur();
        document?.getElementById("firstDigit")?.focus();
        setTimeout(() => setModalWiggle(false), 1000);
      });
    }
  }, [sixDigitCode, checkCodeAndLogin]);
  useEffect(() => {
    if (loginEmailStatus === "success") {
      dispatch({
        type: "CLEAR_LOGIN_TOKEN_EMAIL_STATE",
        payload: undefined
      });
      redirectToLoginSuccess();
    }
  }, [loginEmailStatus, redirectToLoginSuccess, dispatch]);
  const [isEmailInput, setIsEmailInput] = useState(isWebView || false);
  const submitEmail: React.FormEventHandler<HTMLFormElement> = useCallback(e => {
    e.preventDefault();
    if (emailIsValid) {
      onSubmit();
    }
  }, [emailIsValid, onSubmit]);
  const emailButtonClick: React.MouseEventHandler<HTMLDivElement> = useCallback(e => {
    e.preventDefault();
    if (!isEmailInput && !providedEmail || !emailIsValid) {
      setIsEmailInput(true);
    } else {
      onSubmit();
    }
  }, [isEmailInput, providedEmail, emailIsValid, onSubmit]);
  const googleAuthLoginLink = useMemo(() => {
    let emailParam = "";
    if (providedEmail) {
      // handling if already encoded vs. not
      emailParam = `&${NOVEL_PASS_USER_EMAIL_PARAM}=${providedEmail.includes("@") ? encodeURIComponent(providedEmail) : providedEmail}`;
    }
    let redirect = "";
    if (providedOfferLink) {
      if (providedOfferLink.includes("redirectUrl=")) {
        redirect = `https://${resolvedOrg?.currentOrgHandle.handle}.${HOST_WITH_CORRECTED_SUBDOMAINS}/auth?${emailParam}&redirectUrl=${providedOfferLink.split("redirectUrl=")[1]}`;
      } else {
        redirect = `${providedOfferLink}#${qs.stringify({
          [NOVEL_PASS_CAMPAIGN_ID_PARAM]: providedCampaignId,
          [NOVEL_PASS_CODE_PARAM]: providedCode,
          [NOVEL_PASS_LINK_TYPE_PARAM]: providedPassLinkType
        })}`;
      }
    } else {
      // should work on server and client
      redirect = `https://${resolvedHost}${qs.stringify({
        ...pickBy(props, (prop, propKey) => !propKey.startsWith("_sentry"))
      }, {
        addQueryPrefix: true
      })}`;
    }
    return `${oauthPrefixedHostWithProtocol}/oauth/google?originalUrl=${encodeURIComponent(redirect)}${emailParam}`;
  }, [props, providedEmail, providedOfferLink, resolvedHost, resolvedOrg?.currentOrgHandle.handle, providedCampaignId, providedCode, providedPassLinkType]);
  const googleLogin = useCallback(() => {
    window.location.replace(googleAuthLoginLink);
  }, [googleAuthLoginLink]);
  const screenText = () => {
    return referredBy && referredBy.rewardsStatus?.currentRewardsTier.hasReferralReward ? removeTextAlignmentFromDraftJs(referrerLoginWelcomeText) : providedPassLinkType ? passLinkLoginText : removeTextAlignmentFromDraftJs(passLoginWelcomeText);
  };
  return <React.Fragment>
            {/* optimizing google auth by pre-connecting redirect, DO NOT prefetch */}
            {googleAuthLoginLink && <link href={googleAuthLoginLink} as="document" rel="preconnect" />}
            {loginModalState !== "login-code-provided-via-url" && <LayoutDesktopCmp userDisplayName={resolvedUserDisplayName} {...rewardsTier} isLogin passOrgNameOverride={resolvedOrg?.rewardsConfig.passOrgNameOverride} orgName={resolvedOrg?.org.orgName} guessedPhoneOperatingSystem={resolvedPhoneOperatingSystem} iconImageUrl={logoImageUrl} googleLogoImageUrl={rewardsTier?.googleLogoImageUrl || ""} googleStripImageUrl={rewardsTier?.googleStripImageUrl || ""} appleLogoImageUrlx3={rewardsTier?.appleLogoImageUrlx3 || ""} appleLogoImageUrlx2={rewardsTier?.appleLogoImageUrlx2 || ""} appleLogoImageUrl={rewardsTier?.appleLogoImageUrl || ""} stripImageUrlx3={rewardsTier?.stripImageUrlx3 || ""} stripImageUrlx2={rewardsTier?.stripImageUrlx2 || ""} stripImageUrl={stripImageUrl} tierName={rewardsTier?.tierName || ""} welcomeMessage={rewardsTier?.welcomeMessage || ""} balanceLabel={rewardsTier?.balanceLabel || ""} backgroundColor={rewardsTier?.backgroundColor || ""} textColor={rewardsTier?.textColor || ""} authLink={resolvedQrLink} currencyCode={currencyCode}>
                    <Box className="box-login">
                        <Box column rowGap={5.5} className="form-box" alignItems="flex-start" p={isMobile ? 2 : 0}>
                            <Box className="small-logo-container">
                                {resolvedOrg && rewardsTier && <PassAnimation orgHandle={resolvedOrg.currentOrgHandle} rewardsTier={rewardsTier} currencyCode={currencyCode} referredBy={referredBy} />}
                            </Box>
                            {loginModalState === "login-request" && <React.Fragment>
                                    <Box column className="box-content-login-prompt" justifyContent="center" alignItems="center">
                                        <div className="box-content-login-prompt-editor" dangerouslySetInnerHTML={{
                __html: `${screenText()}`
              }} />
                                    </Box>
                                    <Box className="continue-button-container" mobileJustifyContent="center" width="100%" rowGap={1.5} column>
                                        {!isWebView && <Box alignItems="center" columnGap={0.9} justifyContent="center" className="continue-button" onClick={googleLogin}>
                                                <Box minWidth={17}>
                                                    <Image src={gmailAuthIcon} alt="Logo" priority width={22.68683274} height={17} className="auth-icon google-auth-icon" />
                                                </Box>
                                                <div>Continue with Gmail</div>
                                            </Box>}
                                        {!isEmailInput ? <Box alignItems="flex-start" columnGap={1} justifyContent="center" className="continue-button inverse-continue-button" onClick={emailButtonClick}>
                                                <div>All other email providers</div>
                                            </Box> : <Box column width="100%">
                                                <form onSubmit={submitEmail}>
                                                    <Box width="100%" columnGap={1}>
                                                        <input autoFocus className="auth-email-input" placeholder="Enter Your Email" defaultValue={providedEmail || ""} id="email" name="email" autoComplete="email" onChange={onEmailChange} />
                                                        <div className="form-submit-area">
                                                            <button type="submit" className="continue-button send-link-button" disabled={!emailIsValid || loginEmailStatus === "requesting"}>
                                                                Send Login Link
                                                            </button>
                                                        </div>
                                                    </Box>
                                                </form>
                                            </Box>}
                                    </Box>
                                </React.Fragment>}
                            {loginModalState === "login-auth" && <Box column rowGap={5.5} className="login-auth">
                                    {isMobile || noCodeFlag ? <Box column rowGap={1} className="text-for-small-lookups">
                                            <Box className="auth-text">Check Your Email</Box>
                                            <Box className="auth-subtext">
                                                {providedPassLinkType ? "Login to Continue!" : "Your pass is on its way!"}
                                                <br />
                                                We have sent a {providedPassLinkType && "login"}{" "}
                                                link to{" "}
                                                <span style={{
                  fontWeight: "bold",
                  display: "contents"
                }}>
                                                    {" "}
                                                    {providedEmail}{" "}
                                                </span>{" "}
                                                {!providedPassLinkType && "for you to get your pass."}
                                            </Box>
                                            <p style={{
                color: "#bcbcbc"
              }}>
                                                Didn’t receive it?
                                                <span className="resend-code" onClick={async () => {
                  await onSubmit();
                }}>
                                                    {" "}
                                                    Resend email
                                                </span>
                                            </p>
                                            <p className="back-to-login" onClick={() => {
                setLoginModalState("login-request");
                customerPassRoutePush("/login", {
                  referralCode: providedReferralCode,
                  sourceTag: providedSourceTag
                });
              }}>
                                                Back to login
                                            </p>
                                        </Box> : <React.Fragment>
                                            <Box column rowGap={1} className="text-for-small-lookups">
                                                <Box className="auth-text">
                                                    Enter Your Security Code
                                                </Box>
                                                <Box className="auth-subtext">
                                                    Please enter the six digit code found in your
                                                    email{" "}
                                                    <span style={{
                    fontWeight: "bold",
                    display: "contents"
                  }}>
                                                        {" "}
                                                        {providedEmail}
                                                    </span>
                                                </Box>
                                            </Box>
                                            <SixDigitCodeComponent className={cx({
                modalWiggle
              })} value={sixDigitCode} onChange={sixDigitCodeOnChange} />
                                            <Box style={{
                fontSize: "12px",
                marginTop: "-2rem"
              }} column rowGap={1} className="text-for-small-lookups">
                                                {!!codeMessage.length && <Box className="resend-code" style={{
                  cursor: "auto",
                  color: codeMessage === CODE_ERROR ? "red" : "black"
                }}>
                                                        <p>{codeMessage}</p>
                                                    </Box>}
                                                <p style={{
                  color: "#ccc"
                }}>
                                                    Didn't receive your code?
                                                    <span className="resend-code" onClick={async () => {
                    await onSubmit();
                    setCodeMessage(CODE_RESEND);
                  }}>
                                                        {" "}
                                                        Get a new one
                                                    </span>
                                                </p>
                                                <p className="back-to-login" onClick={() => {
                  setLoginModalState("login-request");
                  customerPassRoutePush("/login", {
                    referralCode: providedReferralCode,
                    sourceTag: providedSourceTag
                  });
                }}>
                                                    Back to login
                                                </p>
                                            </Box>
                                        </React.Fragment>}
                                </Box>}
                        </Box>
                    </Box>
                </LayoutDesktopCmp>}
            <LoginPageCmpStyle />
        </React.Fragment>;
};